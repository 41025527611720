import React from 'react';
import { useSelector } from 'react-redux';

import RouteEnum from '../../RouteEnum';
import { AppState } from '../../services/AppState';
import type { ReduxState } from '../../store';
import PageLayout from '../../views/Layout/Page';
import Redirect from '../../views/Redirect';
import MyVehiclesPage from '../../views/Vehicle/MyVehiclesPage';
import MyVehicleTab from '../../views/Vehicle/MyVehicleTabs';

export default function CarsIndexPage() {
  const { selected } = useSelector((state: ReduxState) => state.myCars);

  if (selected) {
    return <Redirect params={{ hash: selected, tab: MyVehicleTab.RESERVATION }} to={RouteEnum.CARS} />;
  }

  return (
    <PageLayout private requiredState={AppState.LOADED}>
      <MyVehiclesPage tab={MyVehicleTab.RESERVATION} />
    </PageLayout>
  );
}
